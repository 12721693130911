.navbar {
    position: relative;
    z-index: 1000; /* Ensure the navbar is above other content */
    min-height: 50px;
    border: 1px transparent;
    background-color: #e2dfdf;
    color: black;
    padding: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0 !important;
}

.menu-icons {
    display: flex;
    z-index: 1001; /* Ensure the icons are clickable */
}

.menu-icons a,
.menu-icons button {
    display: inline-block;
    padding: 5px 20px;
    margin: 5px;
    border-radius: 0.375rem;
    transition: background-color 0.3s ease;
    font-size: 20px;
    color: black;
    cursor: pointer; /* Ensure it shows as clickable */
    text-align: center;
    line-height: 1.5;
    z-index: 1002; /* Ensure buttons are on top */
    position: relative; /* Ensure the button's position is relative to the navbar */
}

.menu-icons button {
    border: none;
    background-color: transparent;
}

.menu-icons a:hover,
.menu-icons button:hover {
    background-color: #bbb;
    color: inherit;
}

@media (max-width: 600px) {
    .menu-icons a,
    .menu-icons button {
        margin: 0 10px;
    }
    .navbar {
        justify-content: space-between;
    }
}
